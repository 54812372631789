<template>
  <div
    class="form-group form-group-multi-checkbox"
    :style="resultType ? 'pointerEvents: none' : ''"
  >
    <div class="multi-checkbox__items">
      <label
        class="multi-checkbox__item"
        v-for="item in items"
        :key="item.title + unique"
        :style="
          resultType && userAnswers && !checkboxesArray.includes(item.title)
            ? 'paddingLeft: 15px'
            : ''
        "
      >
        <span v-if="resultType && userAnswers" class="icon">
          <img
            v-if="findTrueAnswer(item) && checkboxesArray.includes(item.title)"
            src="@/assets/img/Test/correct_answer.svg"
            alt="Верно"
          />
          <img
            v-if="!findTrueAnswer(item) && checkboxesArray.includes(item.title)"
            src="@/assets/img/Test/failed_answer.svg"
            alt="Не верно"
          />
        </span>
        <input
          class="multi-checkbox__input"
          type="checkbox"
          :value="item.title"
          v-model="checkboxesArray"
        />
        <span
          class="multi-checkbox__custom"
          :style="[
            color ? { borderColor: color } : '',
            color && value ? { backgroundColor: color } : ''
          ]"
        >
          <svg
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6563 1.21899L5.09375 7.7812L1.8125 4.50024"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span class="multi-checkbox__label" v-if="item.title">{{ item.title }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiCheckbox",
  mixins: [],
  props: {
    color: {
      type: String
    },
    unique: {
      type: String
    },
    editValue: {
      type: Array,
      default: () => {
        return [];
      }
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    resultType: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    userAnswers: {
      type: Array,
      default: () => {
        return null;
      }
    }
  },
  components: {},
  data() {
    return {
      checkboxesArray: []
    };
  },
  mounted() {
    if (this.editValue) {
      this.checkboxesArray = this.editValue;
    }
    if (this.resultType && this.userAnswers) {
      this.checkboxesArray = this.userAnswers.map((item) => {
        return item.answer;
      });
    }
  },
  methods: {
    // Метод для нахождения верных ответов
    findTrueAnswer(answer) {
      // Если ответ не был выбран, но он был верным
      let flag = answer.correct ? false : true;
      // Пробег по массиву с ответами юзера, если один из всех ответов
      // совпадает с ответом юзера, то проверяется его корректность
      this.userAnswers.forEach((item) => {
        if (item.answer === answer.title) {
          flag = item.is_correct ? true : false;
        }
      });
      return flag;
    }
  },
  computed: {},
  watch: {
    checkboxesArray() {
      this.$root.$emit(`multiCheck_${this.unique}`, this.checkboxesArray);
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.form-group-multi-checkbox {
  .multi-checkbox__items {
    .multi-checkbox__item {
      width: max-content;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 5px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        cursor: pointer;

        .multi-checkbox__custom {
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.07);
        }
      }

      .multi-checkbox__input {
        display: none;
        width: 0;
        height: 0;
        font-size: 0;

        &:checked + .multi-checkbox__custom {
          background-color: $color-interactive;
          border-color: $color-interactive;

          svg {
            opacity: 1;
          }
        }
      }

      .multi-checkbox__custom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;

        border: 1px solid $color-text-secondary;
        border-radius: 2px;
        transition: all 0.3s ease;

        svg {
          opacity: 0;
          transition: all 0.3s ease;
        }
      }

      .multi-checkbox__label {
        margin-left: 6px;
        margin-bottom: -2px;

        font-size: 14px;
        line-height: 13px;
        color: $color-text-primary-2;
      }
    }
  }
}
</style>
